import { useMediaQuery } from "react-responsive";

export const MobileBreakpoint = 950;

export const useMediaQueries = () => {
  const isMobile = useMediaQuery({ maxWidth: MobileBreakpoint });

  return {
    isMobile,
  };
};
