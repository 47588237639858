import './App.css';

import { supabase } from './supabase.js';
import { Auth, ThemeSupa } from '@supabase/auth-ui-react';
import { useEffect, useState } from 'react';

import { useMediaQueries } from './hooks/useMediaQueries';
import Layout from './Layout';

// Auth ------------------------------------------------------------------------

function useAuth() {
  const [user, setUser] = useState();
  useEffect(() => {
    const session = supabase.auth.getSession();
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null);
      }
    )
  }, []);
  return user;
}

function RequireLoggedInUser({style, children}) {
  const user = useAuth();
  if (user) {
    return children;
  }
  return (
    <div id="auth-container" style={style}>
      <Auth supabaseClient={supabase} appearance={{theme: ThemeSupa}}/>
    </div>
  );
}


// Chat ------------------------------------------------------------------------

function ChatMessage({
  message,
  loggedInUserId,
  userId
}) {
  const styling = (loggedInUserId === userId) ? "message message-by-logged-in-user" : "message message-by-other-user";
  return (
    <div className={styling}>
      <p>{message}</p>
      <p> - {loggedInUserId}</p>
      <p> - {userId}</p>
    </div>
  );
}

// This hook is used to grab and listen to chat messages for the given room
function useChatMessages(roomId) {

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let listener = supabase
      .channel(`public:messages:roomId=eq.${roomId}`)
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'messages' }, (payload) => {
        setMessages(prevMessages => ([...prevMessages, payload.new]));
      })
      .subscribe();

    return () => { supabase.removeChannel(listener); }
  }, [roomId]);

  useEffect(() => {
    async function loadMessages() {
      let { data, error } = await supabase
        .from('messages')
        .select('*')
        .eq('roomId', roomId);

        setMessages(data);
    }
    loadMessages();
  }, [roomId]);


  return messages;
}


// <ChatRoom/> is the full chat sidebar, this should probably be renamed
function ChatRoom({
  loggedInUser,
  roomId
}) {

  const messages = useChatMessages(roomId);

  const [inputText, setInputText] = useState('');
  async function sendChatMessage(messageText, roomId) {
    const { data, error } = await supabase
    .from('messages')
    .insert([
      { message: messageText, roomId: roomId},
    ]);
  }

  return (
    <div id="chat-room-container">
      <div id="messages-scroll-container">
        {messages.map((message, i) =>
          <ChatMessage key={i} message={message.message} userId={message.userId} loggedInUserId={loggedInUser?.id}/>
        )}
      </div>
      <div id="chat-input-section-container">
        <input
          id = "chat-text-input"
          value = {inputText}
          onInput = {(e) => {setInputText(e.target.value)}}
          placeholder = "Chat with friends!"
        />
        <button onClick={() => {
          sendChatMessage(inputText, roomId);
          setInputText("");
        }}>Send</button>
      </div>
      <p>length: {messages.length}</p>
    </div>
  );
}


// <App/> ----------------------------------------------------------------------

function DesktopLayout({
  user,
  streams
}) {
  const [sidebarTab, setSidebarTab] = useState(0);
  const [activeStream, setActiveStream] = useState(0);
  useEffect(() => {
    for(const stream of streams) {
      console.log(stream);
    }
    // getLivestreamId();
  }, [])

  function selectSidebar(i) {
    setSidebarTab(i);
  }
  function selectActiveStream(i) {
    setActiveStream(i);
  }

  const currentStream = streams[activeStream];

  return (
    <div className="DesktopLayout">
      <div id="app-navbar-container">
        <StreamTabs tab={activeStream} updateTab={selectActiveStream} streams={streams} />
      </div>

      <div id="app-body-container">
        <div id="app-stream-viewer-page-section">
          <div id="change-this-to-component-stream">
            <IFramePlayer site={currentStream?.stream.site} id={currentStream?.stream.id} />
          </div>
          <div id="change-this-to-component-stream-selector"></div>
        </div>
        <div id="app-sidebar-page-section">
          <SidebarTabs tab={sidebarTab} updateTab={selectSidebar} />
          <div id="app-sidebar-container">
            <IFrameChat stream={currentStream} active={sidebarTab===1} videoId={currentStream?.stream.videoId} />
            <RequireLoggedInUser
              style={{display: sidebarTab===0 ? "block" : "none"}} >
              <ChatRoom loggedInUser={user} roomId={1} />
            </RequireLoggedInUser>
          </div>
        </div>
      </div>
    </div>
  );
}


function StreamTabs({ tab, updateTab, streams }) {
  return (
    <div id="app-navbar-tabs">
      { streams.map((info, i) => (
        <span key={i} className={`${tab === i ? "selected" : ""}`} onClick={() => updateTab(i)}>{info.label}</span>
      ))}
    </div>
  );
}

function SidebarTabs({ tab, updateTab }) {

  return (
    <div id="app-sidebar-tabs">
      <span className={`${tab === 0 ? "selected" : ""}`} onClick={() => updateTab(0)}>Custom Chat</span>
      <span className={`${tab === 1 ? "selected" : ""}`} onClick={() => updateTab(1)}>Stream Chat</span>
    </div>
  );
}

function IFramePlayer({ site, id }) {
  if(site === "twitch") {
    return (
      <iframe
        src={`https://player.twitch.tv/?channel=${id}&parent=watch-pandacup.pages.dev`}
        height="100%"
        width="100%"
        allowFullScreen >
      </iframe>
    );
  }
  return (
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/live_stream?channel=${id}`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
    </iframe>
  );
}

function IFrameChat({stream, active, videoId}) {
  let show = active ? "block" : "none"
  if(!stream || (stream.stream.site === "youtube" && !videoId)) {
    return <div style={{display: show}}>This is empty</div>;
  }

  if(stream.stream.site === "twitch") {
    return (
      <iframe
        style={{display: show}}
        src={`https://www.twitch.tv/embed/${stream.stream.id}/chat?parent=watch-pandacup.pages.dev`}
        height="100%"
        width="100%">
      </iframe>
    );
  }
  return (
    <iframe
      style={{display: show}}
      width="100%"
      height="100%"
      src={`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=watch-pandacup.pages.dev`} >
    </iframe>
  );
}

function MobileLayout({
  user,
  streams
}) {
  const [tab, setTab] = useState(0);

  function update(i) {
    setTab(i);
  }

  return (
    <div className="MobileLayout">
      <div id="app-navbar-container">Mobile Navbar</div>

      <div id="app-body-container">
        <div id="app-stream-viewer-page-section">
          <div id="change-this-to-component-stream">
            <IFramePlayer site={"twitch"} />
          </div>
          <div id="change-this-to-component-stream-selector"></div>
        </div>
        <div id="app-sidebar-page-section">
          <SidebarTabs tab={tab} updateTab={update} streams={streams} />
          <div id="app-sidebar-container">
              <RequireLoggedInUser
                style={{display: tab===0 ? "block" : "none"}} >
                <ChatRoom loggedInUser={user} roomId={1} />
              </RequireLoggedInUser>
              <IFrameChat site={"twitch"} active={tab===1}/>
          </div>
        </div>
      </div>
    </div>
  );
}


function App() {
  const STREAMS = [
    // {label: "ULT 1", stream: {site: "youtube", id: "UCBODyKF0JMbUd6D9Bklyzbw", alt: "Alpharad"}, },
    // {label: "ULT 2", stream: {site: "youtube", id: "UCo1HYjIZ7fEMElpPQOruSDQ", alt: "Mogul Moves"}, },
    // {label: "MELEE 1", stream: {site: "youtube", id: "UCrPseYLGpNygVi34QpGNqpA", alt: "Ludwig"}, },
    // {label: "MELEE 2", stream: {site: "youtube", id: "UCmbSGFM9OU8FwjxZCevr6zw", alt: "Ludwig VODs"}, },
    // {label: "TEST TWITCH", stream: {site: "twitch", id: "saltybet", alt: "SaltyBet"}, },
    {label: "LOFI", stream: {site: "youtube", id: "UCSJ4gkVC6NrvII8umztf0Ow", alt: "LoFi"}, }
  ];

  const [streams, setStreams] = useState([]);
  const { isMobile } = useMediaQueries();

  useEffect(() => {
    console.log("getting chat links");
    async function getLivestreamId() {
      const GET_JSON_HEADERS = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With"
        },
        method: "GET",
      };

      for(const s of STREAMS) {
        console.log(s);
        if(s.stream.site === "youtube") {
          const regex = /canonical" href="https\:\/\/www.youtube.com\/watch\?v=([^"]+)"/;
          const response = await fetch(`https://www.youtube.com/embed/live_stream?channel=${s.stream.id}`, GET_JSON_HEADERS);
          const js = await response.text();
          s.stream.videoId = js.match(regex)[1];
        }
      }

      setStreams(STREAMS);
      console.log("setStreams", streams);
    }

    getLivestreamId();
  }, []);

  const user = useAuth();
  function Contents({mobile, user}) {
    console.log(mobile);
    if(mobile) {
      return <MobileLayout user={user} streams={streams} />;
    }
    return <DesktopLayout user={user} streams={streams} />;
  }

  return (
    <div className="App">
      <Contents mobile={isMobile} user={user} />
    </div>
  );
}

export default App;
